import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

const VentasRegistradas = () => {
    const [ventas, setVentas] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentVenta, setCurrentVenta] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchVentas = async () => {
            try {
                const response = await axios.get('https://gavilanes.com.mx/SCRV/ventas/obtenerVentas.php');
                setVentas(response.data);
            } catch (error) {
                console.error('Error fetching sales:', error);
            }
        };

        fetchVentas();
    }, []);

    const openModal = (venta) => {
        setCurrentVenta(venta);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setCurrentVenta(null);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setCurrentVenta({ ...currentVenta, [name]: value });
    };

    const handleSave = async () => {
        try {
            await axios.post('https://gavilanes.com.mx/SCRV/ventas/editarVenta.php', currentVenta);
            setIsModalOpen(false);
            setCurrentVenta(null);
            // Refetch ventas to update the list
            const response = await axios.get('https://gavilanes.com.mx/SCRV/ventas/obtenerVentas.php');
            setVentas(response.data);
        } catch (error) {
            console.error('Error saving sale:', error);
        }
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Ventas Registradas</h2>
            <div style={styles.tableContainer}>
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th>ID Venta</th>
                            <th>Cliente</th>
                            <th>Sucursal</th>
                            <th>Fecha</th>
                            <th>Total</th>
                            <th>Folio</th>
                            <th>Usuario que registró la venta</th>
                            <th>Notas</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ventas.map((venta) => (
                            <tr key={venta.id_venta}>
                                <td style={styles.td}>{venta.id_venta}</td>
                                <td style={styles.td}>{venta.cliente || 'Detalle'}</td>
                                <td style={styles.td}>{venta.sucursal}</td>
                                <td style={styles.td}>{venta.fecha}</td>
                                <td style={styles.td}>{"$" + venta.total}</td>
                                <td style={styles.td}>{String(venta.folio).padStart(5, '0')}</td>
                                <td style={styles.td}>{venta.usuario}</td>
                                <td style={styles.td}>{venta.notas}</td>
                                <td style={styles.td}>
                                    <button
                                        style={styles.detailButton}
                                        onClick={() => navigate(`/detalle-venta/${venta.id_venta}`)}
                                    >
                                        Detalle
                                    </button>
                                    <button
                                        style={styles.editButton}
                                        onClick={() => openModal(venta)}
                                    >
                                        Editar
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {currentVenta && (
                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    contentLabel="Editar Venta"
                    style={modalStyles}
                >
                    <h2 style={styles.modalTitle}>Editar Venta | {currentVenta.sucursal}</h2>
                    <form style={styles.form}>
                        <label style={styles.label}>
                            Total $:
                            <input
                                type="number"
                                name="total"
                                value={currentVenta.total}
                                onChange={handleEditChange}
                                style={styles.input}
                            />
                        </label>
                        <label style={styles.label}>
                            Folio:
                            <input
                                type="text"
                                name="folio"
                                value={currentVenta.folio}
                                onChange={handleEditChange}
                                style={styles.input}
                            />
                        </label>
                        <div style={styles.buttonContainer}>
                            <button type="button" onClick={handleSave} style={styles.saveButton}>Guardar</button>
                            <button type="button" onClick={closeModal} style={styles.cancelButton}>Cancelar</button>
                        </div>
                    </form>
                </Modal>
            )}
        </div>
    );
};

const styles = {
    container: {
        maxWidth: '90%',
        margin: 'auto',
        padding: '20px',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    title: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#333',
    },
    tableContainer: {
        overflowX: 'auto',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        backgroundColor: '#fff',
    },
    th: {
        border: '1px solid #ddd',
        padding: '12px',
        textAlign: 'center',
        backgroundColor: '#f2f2f2',
        color: '#333',
        fontWeight: 'bold',
    },
    td: {
        border: '1px solid #ddd',
        padding: '12px',
        textAlign: 'center',
        color: '#333',
    },
    detailButton: {
        padding: '5px 10px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        marginRight: '5px',
    },
    editButton: {
        padding: '5px 10px',
        backgroundColor: '#28a745',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    modalTitle: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#333',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    label: {
        display: 'flex',
        flexDirection: 'column',
        fontWeight: 'bold',
        color: '#333',
    },
    input: {
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid #ddd',
        marginTop: '5px',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '20px',
    },
    saveButton: {
        padding: '10px 20px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    cancelButton: {
        padding: '10px 20px',
        backgroundColor: '#dc3545',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
};

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        width: '400px',
    },
};

export default VentasRegistradas;