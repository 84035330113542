import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import axios from 'axios';
import { format } from 'date-fns';

Modal.setAppElement('#root');

const RegistrarVenta = () => {
    const [clientes, setClientes] = useState([]);
    const [sucursales, setSucursales] = useState([]);
    const [productos, setProductos] = useState([]);
    const [filtroClientes, setFiltroClientes] = useState('');
    const [filtroSucursales, setFiltroSucursales] = useState('');
    const [filtroProductos, setFiltroProductos] = useState('');
    const [ventaProductos, setVentaProductos] = useState([]);
    const [clienteSeleccionado, setClienteSeleccionado] = useState(null);
    const [sucursalSeleccionada, setSucursalSeleccionada] = useState(null);
    const [metodoPago, setMetodoPago] = useState('Contado');
    const [total, setTotal] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modoVenta, setModoVenta] = useState('Conveniencia');
    const [notas, setNotas] = useState('');
    const [fechaActual, setFechaActual] = useState('');
    const [error, setError] = useState('');
    const [sucursalesCliente, setSucursalesCliente] = useState([]);
    const [promocion, setPromocion] = useState(null);
    const [promocionesAplicadas, setPromocionesAplicadas] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        if (clienteSeleccionado) {
            const fetchSucursalesCliente = async () => {
                try {
                    const response = await axios.get(`https://gavilanes.com.mx/SCRV/sucursales/obtener_sucursales_por_cliente.php?id_cliente=${clienteSeleccionado.id_cliente}&tipo_cliente=${clienteSeleccionado.tipo_cliente}`);
                    setSucursalesCliente(response.data);
                } catch (error) {
                    alert("Error al obtener las sucursales: " + error.message);
                }
            };

            fetchSucursalesCliente();
        }
    }, [clienteSeleccionado]);

    useEffect(() => {
        setVentaProductos([]);
        setPromocion(null);
        setTotal(0);
        setPromocionesAplicadas({});
        setError('');
    }, [modoVenta]);

    useEffect(() => {
        setVentaProductos([]);
        setPromocion(null);
        setTotal(0);
    }, [modoVenta]);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user) {
            navigate('/login');
        } else if (user.tipo_usuario !== '1') {
            navigate('/rutas');
        }
        setFechaActual(format(new Date(), 'dd/MM/yyyy'));
    }, [navigate]);

    useEffect(() => {
        setSucursalSeleccionada(null);
        setFiltroSucursales('');
    }, [modoVenta]);

    useEffect(() => {
        const fetchClientes = async () => {
            const response = await axios.get('https://gavilanes.com.mx/SCRV/clientes/obtener_clientes.php');
            setClientes(response.data);
        };

        const fetchSucursales = async () => {
            const response = await axios.get('https://gavilanes.com.mx/SCRV/sucursales/obtener_sucursales.php');
            setSucursales(response.data);
        };

        const fetchProductos = async () => {
            const response = await axios.get('https://gavilanes.com.mx/SCRV/productos/obtener_productos.php?venta=1');
            setProductos(response.data);
        };

        fetchClientes();
        fetchSucursales();
        fetchProductos();
    }, []);

    const agregarProducto = async (producto) => {
        try {
            const response = await axios.get(`https://gavilanes.com.mx/SCRV/precios/obtener_precios.php?id_producto=${producto.id_producto}`);
            const precio = response.data.precio_publico || 0;
    
            setVentaProductos(prev => {
                const productoExistente = prev.find(p => p.id_producto === producto.id_producto);
                let nuevaLista;
    
                if (productoExistente) {
                    const nuevaCantidad = productoExistente.cantidad + 1;
                    let descuento = 0;
    
                    if (modoVenta === "Detalle") {  // 💡 Solo aplica descuento si es "detalle"
                        const cantidadDescuento = Math.floor(nuevaCantidad / 4);
                        descuento = cantidadDescuento * precio;
                    }
    
                    nuevaLista = prev.map(p =>
                        p.id_producto === producto.id_producto
                            ? { ...p, cantidad: nuevaCantidad, descuento }
                            : p
                    );
    
                    if (modoVenta === "Detalle" && descuento > 0) {
                        setPromocion({ ...productoExistente, descuento });
                    }
                } else {
                    nuevaLista = [...prev, { ...producto, cantidad: 1, precio_unitario: precio, descuento: 0 }];
                }
    
                return nuevaLista;
            });
    
        } catch (error) {
            alert("Error al obtener el precio del producto: " + error.message);
        }
    };    
    
    const aplicarDescuento = () => {
        if (modoVenta !== "Detalle") return;
    
        console.log('Productos antes de aplicar descuento:', ventaProductos);
    
        const nuevosProductos = ventaProductos.map(producto => {
            if (producto.id_producto === promocion.id_producto) {
                const cantidadDescuento = Math.floor(producto.cantidad / 4);
                const descuento = cantidadDescuento * producto.precio_unitario;
    
                return { 
                    ...producto, 
                    descuento: descuento 
                };
            }
            return producto;
        });
    
        console.log('Productos después de aplicar descuento:', nuevosProductos);
    
        setVentaProductos(nuevosProductos);
    
        const nuevoTotal = nuevosProductos.reduce((acc, producto) => 
            acc + (producto.precio_unitario * producto.cantidad) - producto.descuento, 0
        );
    
        console.log('Nuevo total calculado:', nuevoTotal);
    
        setTotal(nuevoTotal);
    
        setPromocionesAplicadas(prev => ({
            ...prev,
            [promocion.id_producto]: `Descuento de $${promocion.descuento.toFixed(2)}`,
        }));
    
        setPromocion(null);
    };

    useEffect(() => {
        const nuevoTotal = ventaProductos.reduce((acc, p) => acc + (p.precio_unitario * p.cantidad - p.descuento), 0);
        console.log('Actualizando total aa:', nuevoTotal);
        setTotal(nuevoTotal);
    }, [ventaProductos]);
    
    const ignorarDescuento = () => {
        // Solo quita el descuento del producto que tiene la promoción aplicada
        setVentaProductos(prev => 
            prev.map(p => 
                p.id_producto === promocion.id_producto 
                    ? { ...p, descuento: 0 } // Elimina el descuento solo del producto promocionado
                    : p // Deja el descuento de los otros productos igual
            )
        );
        setPromocion(null);  // Limpia la promoción
        calcularTotal(); // Recalcula el total
    };
    

    const calcularTotal = () => {
        const nuevoTotal = ventaProductos.reduce((acc, producto) => 
            acc + (producto.precio_unitario * producto.cantidad) - producto.descuento, 0
        );
        setTotal(nuevoTotal);
    };    

    const actualizarCantidad = (id_producto, cantidad) => {
        setVentaProductos(prev => {
            return prev.map(producto => {
                if (producto.id_producto === id_producto) {
                    let descuento = 0;
    
                    if (modoVenta === "Detalle") {  // Solo aplica descuento si es "detalle"
                        const cantidadDescuento = Math.floor(cantidad / 4);
                        descuento = cantidadDescuento * producto.precio_unitario;
                    }
    
                    if (modoVenta === "Detalle" && descuento > 0) {
                        setPromocion({ ...producto, descuento });
                    }
    
                    return { ...producto, cantidad, descuento };
                }
                return producto;
            });
        });
    
        // Recalcula el total después de actualizar la cantidad
        calcularTotal();
    };
    
    const eliminarProducto = (id_producto) => {
        setVentaProductos(prev => prev.filter(p => p.id_producto !== id_producto));
    };

    useEffect(() => {
        if (modoVenta === 'Detalle') {
            setMetodoPago('Contado');
            setClienteSeleccionado(null);
        }
    }, [modoVenta]);

    useEffect(() => {
        if (modoVenta === 'Conveniencia' || modoVenta === 'Autoservicio') {
            setMetodoPago('Crédito');
            setClienteSeleccionado(null);
        }
    }, [modoVenta]);

    const registrarVenta = async () => {
        // Verificar si la sucursal está seleccionada
        if ((modoVenta !== 'Detalle' && (!clienteSeleccionado || !sucursalSeleccionada)) ||
            ventaProductos.length === 0 || !metodoPago || !total) {
            setError('Todos los campos son obligatorios.');
            return;
        }
    
        // Verifica si es venta tipo "Detalle" y si no hay tienda seleccionada
        if (modoVenta === 'Detalle' && !sucursalSeleccionada) {
            setError('Debe seleccionar una tienda.');
            return;
        }
    
        const ventaData = {
            total,
            notas,
            total_piezas: ventaProductos.reduce((acc, p) => acc + p.cantidad, 0),
            metodo_pago: metodoPago,
            sucursal: sucursalSeleccionada.id_sucursal, // Aseguramos que la sucursal está seleccionada
            id_cliente: clienteSeleccionado?.id_cliente,
            id_usuario: 1,
            tipo_venta: modoVenta,
            detalles: ventaProductos.map(producto => ({
                id_producto: producto.id_producto,
                cantidad: producto.cantidad,
                precio_unitario: producto.precio_unitario
            }))
        };
    
        try {
            const response = await axios.post('https://gavilanes.com.mx/SCRV/ventas/registrar_venta.php', {
                productos: ventaData,
                total,
                metodoPago,
                promociones: promocionesAplicadas
            });
            const folio = String(response.data.folio).padStart(5, '0');
            alert("Venta correctamente registrada con el folio: " + folio);
            setVentaProductos([]);
            setTotal(0);
            setClienteSeleccionado(null);
            setSucursalSeleccionada(null);
            setNotas('');
            setFiltroProductos('');
            setError('');
        } catch (error) {
            alert("Error al registrar la venta: " + error.message);
        }
    };    

    const seleccionarProductoPorCodigo = () => {
        const producto = productos.find(p => p.id_producto === filtroProductos);
        if (producto) {
            agregarProducto(producto);
            setFiltroProductos('');
        } else {
            alert("Producto no encontrado");
        }
    };

    return (
        <div style={styles.container}>
            <span style={styles.date}>{fechaActual}</span>
            <h2 style={styles.header}>Registrar venta</h2>

            <div style={styles.modeSelection}>
                <button onClick={() => navigate('/VentasRegistradas')} style={styles.viewSalesButton}>Ventas registradas</button>
                <button 
                    style={modoVenta === 'Conveniencia' ? styles.activeButton : styles.button} 
                    onClick={() => setModoVenta('Conveniencia')}
                >
                    Conveniencia
                </button>
                <button 
                    style={modoVenta === 'Detalle' ? styles.activeButton : styles.button} 
                    onClick={() => setModoVenta('Detalle')}
                >
                    Detalle
                </button>
                <button 
                    style={modoVenta === 'Autoservicio' ? styles.activeButton : styles.button} 
                    onClick={() => setModoVenta('Autoservicio')}
                >
                    Autoservicio
                </button>
            </div>

            {(modoVenta === 'Conveniencia' || modoVenta === 'Autoservicio') && (
                <div style={styles.section}>
                    <h3>Seleccione el cliente</h3>
                    <input
                        type="text"
                        placeholder="Buscar cliente por nombre"
                        value={filtroClientes}
                        onChange={(e) => setFiltroClientes(e.target.value)}
                        style={styles.input}
                    />
                    {filtroClientes && clientes.filter(cliente => 
                        cliente.cliente.toLowerCase().includes(filtroClientes.toLowerCase()) &&
                        cliente.tipo_cliente === modoVenta // Filter by tipo_cliente based on modoVenta
                    )
                    .map(cliente => (
                        <p
                            key={cliente.id_cliente}
                            style={styles.option}
                            onClick={() => {
                                // Conditionally set tipo_cliente when "Autoservicio"
                                const selectedCliente = { ...cliente, tipo_cliente: modoVenta === 'Autoservicio' ? 'Autoservicio' : cliente.tipo_cliente };
                                setClienteSeleccionado(selectedCliente);
                                setFiltroClientes('');
                            }}
                        >
                            {cliente.cliente}
                        </p>
                    ))}
                    {clienteSeleccionado && <p>Cliente seleccionado: {clienteSeleccionado.cliente}</p>}
                </div>
            )}


<div style={styles.section}>
                <h3>{modoVenta === 'Detalle' ? 'Seleccione la tienda' : 'Seleccione la sucursal'}</h3>
                {modoVenta === 'Conveniencia' && !clienteSeleccionado && (
                    <p style={{ color: 'red' }}>Debe seleccionar un cliente primero</p>
                )}
                <input
                    type="text"
                    placeholder={`Buscar ${modoVenta === 'Detalle' ? 'tienda' : 'sucursal'}...`}
                    value={filtroSucursales}
                    onChange={(e) => setFiltroSucursales(e.target.value)}
                    disabled={modoVenta === 'Conveniencia' && !clienteSeleccionado} // Solo deshabilita en Conveniencia sin cliente
                    style={{
                        ...styles.input,
                        backgroundColor: (modoVenta === 'Conveniencia' && !clienteSeleccionado) ? '#f2f2f2' : 'white',
                        cursor: (modoVenta === 'Conveniencia' && !clienteSeleccionado) ? 'not-allowed' : 'text',
                    }}
                />

                {filtroSucursales &&
                    (modoVenta === 'Detalle' ? sucursales : sucursalesCliente)
                        .filter(sucursal =>
                            (modoVenta !== 'Detalle' || sucursal.tipo_cliente === modoVenta) &&
                            sucursal.razon_social?.toLowerCase().includes(filtroSucursales?.toLowerCase() || '')
                        )
                        .map(sucursal => (
                            <p
                                key={sucursal.id_sucursal}
                                style={styles.option}
                                onClick={() => {
                                    setSucursalSeleccionada(sucursal);
                                    setFiltroSucursales('');
                                }}
                            >
                                {sucursal.razon_social}
                            </p>
                        ))
                }

                {sucursalSeleccionada && (
                    <p>
                        {modoVenta === 'Detalle' ? 'Tienda seleccionada' : 'Sucursal seleccionada'}: {sucursalSeleccionada.razon_social}
                    </p>
                )}
            </div>

            <div style={styles.section}>
                <h3>Seleccionar productos</h3>
                <input
                    type="text"
                    placeholder="Escribir código de producto"
                    value={filtroProductos}
                    onChange={(e) => setFiltroProductos(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && seleccionarProductoPorCodigo()}
                    style={styles.input}
                />
                <button onClick={() => setIsModalOpen(true)} style={styles.button}>Abrir Catálogo</button>
            </div>

            <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} style={styles.modal}>
                <button onClick={() => setIsModalOpen(false)} style={styles.closeButton}>Cerrar</button>
                <h2>Catálogo de Productos</h2>
                <input
                    type="text"
                    placeholder="Buscar producto por nombre"
                    value={filtroProductos}
                    onChange={(e) => setFiltroProductos(e.target.value)}
                    style={styles.input}
                />
                {productos.filter(producto => producto.nombre_producto.toLowerCase().includes(filtroProductos.toLowerCase()))
                    .map(producto => (
                        <div key={producto.id_producto} style={styles.productItem}>
                            <span>{producto.nombre_producto}</span>
                            <button onClick={() => agregarProducto(producto)}>Agregar</button>
                        </div>
                    ))
                }
            </Modal>

            <div style={styles.section}>
                <h3>Productos Seleccionados</h3>
                {ventaProductos.map(producto => (
                    <div key={producto.id_producto} style={styles.productItem}>
                        <span>{producto.nombre_producto}</span>
                        <input
                            type="number"
                            value={producto.cantidad}
                            onChange={(e) => actualizarCantidad(producto.id_producto, parseInt(e.target.value))}
                            style={styles.quantityInput}
                            disabled={promocion && promocion.id_producto !== producto.id_producto}
                        />
                        
                        <button onClick={() => eliminarProducto(producto.id_producto)} style={styles.deleteButton}>🗑️</button>
                    </div>
                ))}
            </div>
            {promocion && (
                <div style={styles.promocion}>
                    <p>Promoción disponible para {promocion.nombre_producto}: Descuento de ${promocion.descuento.toFixed(2)}</p>
                    <button onClick={aplicarDescuento}>Aplicar descuento</button>
                    <button onClick={ignorarDescuento}>No aplicar</button>
                </div>
            )}
            <div style={styles.section}>
                <h3>Método de pago</h3>
                <label>
                    <input
                        type="radio"
                        name="metodoPago"
                        checked={metodoPago === 'Contado'}
                        onChange={() => setMetodoPago('Contado')}
                    />
                    Contado
                </label>
                <label>
                    <input
                        type="radio"
                        name="metodoPago"
                        checked={metodoPago === 'Crédito'}
                        onChange={() => setMetodoPago('Crédito')}
                    />
                    Crédito
                </label>
            </div>            
            <div style={styles.inputGroup}>
                <label style={styles.label}>Notas</label>
                <textarea
                    style={styles.textarea}
                    value={notas}
                    onChange={(e) => setNotas(e.target.value)}
                    placeholder="Notas adicionales"
                />
            </div>

            <div style={styles.section}>
                <h3>Total: ${total.toFixed(2)}</h3>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <button onClick={registrarVenta} style={styles.registerButton}>Registrar venta</button>
            </div>
        </div>
    );
};

const styles = {
    container: { 
        maxWidth: '600px', 
        margin: 'auto', 
        padding: '20px', 
        backgroundColor: '#f4f4f9', 
        borderRadius: '8px', 
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' 
    },
    promocion: {
        backgroundColor: '#f9f9f9',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        marginBottom: '10px',
    },
    header: { 
        textAlign: 'center', 
        color: '#333', 
        fontSize: '24px', 
        fontWeight: '600', 
        marginBottom: '20px' 
    },
    modeSelection: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px'
    },
    button: { 
        padding: '10px 15px', 
        backgroundColor: '#007bff', 
        color: 'white', 
        borderRadius: '6px', 
        cursor: 'pointer', 
        fontWeight: '500', 
        transition: 'background-color 0.3s', 
        border: 'none',
        marginRight: '10px'
    },
    date: {
        float: 'right',
        fontSize: '15px',
        color: '#555',
    },
    viewSalesButton: {
        padding: '10px 15px',
        backgroundColor: '#28a745',
        color: '#fff',
        borderRadius: '6px', 
        cursor: 'pointer', 
        fontWeight: '500', 
        transition: 'background-color 0.3s', 
        border: 'none',
        marginRight: '10px'
    },
    activeButton: { 
        padding: '10px 15px', 
        backgroundColor: '#0056b3', 
        color: 'white', 
        borderRadius: '6px', 
        cursor: 'pointer', 
        fontWeight: '500', 
        transition: 'background-color 0.3s', 
        border: 'none',
        marginRight: '10px'
    },
    section: { 
        marginBottom: '20px', 
        padding: '10px', 
        backgroundColor: '#fff', 
        borderRadius: '8px', 
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' 
    },
    textarea: {
        width: '100%',
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid #ddd',
        resize: 'vertical',
        width: '97%', 
    },
    input: { 
        padding: '10px', 
        width: '95%', 
        marginBottom: '10px', 
        borderRadius: '6px', 
        border: '1px solid #ddd', 
        fontSize: '14px' 
    },
    option: { 
        padding: '8px 12px', 
        cursor: 'pointer', 
        backgroundColor: '#f9f9f9', 
        borderRadius: '4px', 
        marginBottom: '5px', 
        transition: 'background-color 0.3s', 
        fontSize: '14px' 
    },
    closeButton: { 
        padding: '10px 15px', 
        position: 'absolute',
        top: '10px',
        right: '10px',
        backgroundColor: '#dc3545', 
        color: 'white', 
        borderRadius: '6px', 
        cursor: 'pointer', 
        fontWeight: '500', 
        border: 'none' 
    },
    modal: { 
        content: { 
            padding: '20px', 
            maxWidth: '500px', 
            margin: 'auto', 
            backgroundColor: '#fff', 
            borderRadius: '8px', 
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)' 
        } 
    },
    productItem: { 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        padding: '8px', 
        backgroundColor: '#f7f7f9', 
        borderRadius: '6px', 
        marginBottom: '10px' 
    },
    quantityInput: { 
        width: '50px', 
        padding: '5px', 
        textAlign: 'center', 
        borderRadius: '4px', 
        border: '1px solid #ddd' 
    },
    deleteButton: { 
        backgroundColor: '#dc3545', 
        color: 'white', 
        padding: '5px 10px', 
        borderRadius: '4px', 
        cursor: 'pointer', 
        fontWeight: '500', 
        border: 'none' 
    },
    registerButton: { 
        width: '100%', 
        padding: '15px', 
        backgroundColor: '#28a745', 
        color: 'white', 
        borderRadius: '8px', 
        cursor: 'pointer', 
        fontWeight: '600', 
        border: 'none', 
        fontSize: '16px', 
        transition: 'background-color 0.3s' 
    }
};

export default RegistrarVenta;
